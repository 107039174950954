import { takeLatest, put, call, cancelled } from 'redux-saga/effects';
import {
  GET_TRANSFER_DETAIL_REQUEST,
  getTransferDetailSuccess,
  getTransferDetailFail,
  REPORT_TRANSFER_REQUEST
} from 'redux/actions/transferActions';
import { responseOK } from 'utils';
import { getByTransferId, reportTransferQueued } from 'services/transfers';
import instance from 'services/request';
import { sendNotification } from 'helpers/helperNotifications';
import { t } from 'i18next';

export function* requestTransferDetail(action) {
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();
  try {
    const { payload } = action;

    const response = yield call(getByTransferId, payload, source.token);
    if (responseOK(response)) {
      // eslint-disable-next-line no-console
      console.log('response :>> ', response);
      yield put(getTransferDetailSuccess(response.data));
    } else {
      yield put(getTransferDetailFail());
    }
  } catch (_e) {
    // eslint-disable-next-line no-console
    console.log('e :>> ', _e);
    yield put(getTransferDetailFail());
  } finally {
    if (yield cancelled()) {
      source.cancel('cancelled REQUEST');
      yield put(getTransferDetailFail());
    }
  }
}

export function* requestReportTransfer(action){
  try {
    const response = yield call(reportTransferQueued, action.payload);
    if(responseOK(response)){
      sendNotification('success', t('on.file.queued'));
    }else{
      sendNotification('error', t('MSG_002'));
    }
  } catch (error) {
    console.log(error);
    sendNotification('error', t('MSG_002'));
  }
}

export function* watchRequest() {
  yield takeLatest(GET_TRANSFER_DETAIL_REQUEST, requestTransferDetail);
}

export function* watchReportTransferRequest() {
  yield takeLatest(REPORT_TRANSFER_REQUEST, requestReportTransfer);
}
