export const getCatalogFromState = (state, key) => state.catalogs[key];
export const getProfiles = state => state.catalogs.profiles;
export const getPhotos = state => state.case.photos;
export const getPhotosCollected = state => state.transfers.recolectedPhotos;
export const getPhotosDelivered = state => state.transfers.deliveredPhotos;
export const getAudatexPhotos = state => state.case.audatexPics;
export const getInventoryPhotos = state => state.inventoryPhotos.incoming;
export const getInventoryPhotosCheckout = state => state.inventoryPhotos.checkout;

export const getfavoritesCountCases = state => state.case?.favoritesCount;
export const getInventoryIncomingDetail = state => state.vehicleIncomingDetails?.data?.favoritePics;
export const vehicleEntriesDetail = state => state.vehicleEntryDetails?.data?.favoritePics;
export const getTotalFavorite = state => state.updateImagesEntries?.favoriteTotal;
export const getPaginationFromState = (state, key) => state.pagination[key];
export const getFromStore = (state, key, subkey) => state[key]?.[subkey];
export const getFromAwardings = (state, key) => state.awardings[key];

export const getOrderCasePhoto = state => state.case?.photos?.data;
