import { takeLatest, put, call } from 'redux-saga/effects';
import {
  INSURED_REQUEST_SEARCH,
  searchSuccess,
  searchFail
} from 'redux/actions/insuredExternalActions';
import { searchCases } from 'services/cases';

function* requestSearchExternalInsuredAsync(action) {
  const { payload } = action;
  const { searchParams, onSearchSuccess, onSearchFail } = payload;
  try {
    const response = yield call(searchCases, searchParams);
    if (typeof response !== 'undefined' && response.status === 200) {
      yield put(searchSuccess(payload));
      yield onSearchSuccess(response);
    } else {
      yield put(searchFail());
      yield onSearchFail();
    }
  } catch (error) {
    yield onSearchFail();
    yield put(searchFail());
  }
}

/* This is watching action REQUEST_COUNTRIES, whenever is executed it starts
orchestrating the sideEffects   */
export function* watchSearchCasesInsuredERequest() {
  yield takeLatest(INSURED_REQUEST_SEARCH, requestSearchExternalInsuredAsync);
}
