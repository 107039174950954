import { takeLatest, put, call, cancelled } from 'redux-saga/effects';
import {
  DOWNLOAD_FILE_REQUEST,
  downloadFileFail,
  downloadFileSuccess,
  DOWNLOAD_FILE_PREVIEW_REQUEST,
  downloadFilePreviewSuccess,
  downloadFilePreviewFail
} from 'redux/actions/downloadFile';
import instance from 'services/request';
import { forceDownloadFile } from 'services/downloadFile';
import { responseOK } from 'utils';
import { sendNotification } from 'helpers/helperNotifications';

function* requestDownloadFile(action) {
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();
  try {
    const { payload } = action;
    const { params, url, name, successMessage, onFail, advertId, reportType } = payload;

    const response = yield call(forceDownloadFile, url, params, source.token);

    if (responseOK(response)) {
      sendNotification('success', successMessage);
      if(reportType) {
        yield put(downloadFileSuccess({advertId, reportType}));
      }else{
        const type = response.headers['content-type'];
        // const fileName = response.request.getResponseHeader('Content-Disposition');
        // const fileN = response.headers['Content-Disposition'];
        //console.log('fileN :>> ', fileN);
        const blob = new Blob([response.data], { type: type, encoding: 'UTF-8' });
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = name;
        link.click();
        yield put(downloadFileSuccess());
      }
      // yield onSuccess();
    } else {
      if (onFail) {
        yield onFail();
      }
      yield put(downloadFileFail());
    }
  } catch (e) {
    yield put(downloadFileFail());
  } finally {
    if (yield cancelled()) {
      source.cancel('cancelled RQUEST');
      yield put(downloadFileFail());
    }
  }
}

function* requestDownloadFilePreview(action) {
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();
  const { payload } = action;
  const {
    params,
    fileId,
    serviceToDownload,
    headerBase64,
    onDownloadFail,
    onDownloadSuccess
  } = payload;
  try {
    const response = yield call(serviceToDownload, params, source.token);
    if (responseOK(response)) {
      yield put(downloadFilePreviewSuccess({ fileId: response.data.data.fileId }));
      onDownloadSuccess({ ...response.data.data, headerBase64 });
    } else {
      if (onDownloadFail) {
        onDownloadFail(response);
      }
      yield put(downloadFilePreviewFail({ fileId }));
    }
  } catch (e) {
    yield put(downloadFilePreviewFail({ fileId }));
  } finally {
    if (yield cancelled()) {
      source.cancel('cancelled RQUEST');
      yield put(downloadFilePreviewFail({ fileId }));
    }
  }
}

export function* watchDownload() {
  yield takeLatest(DOWNLOAD_FILE_REQUEST, requestDownloadFile);
}

export function* watchDownloadPreview() {
  yield takeLatest(DOWNLOAD_FILE_PREVIEW_REQUEST, requestDownloadFilePreview);
}
