import { takeEvery, put, select, call } from 'redux-saga/effects';
import {
  PROFILES_REQUEST,
  profilesSuccess,
  profilesFail,
  rolesSuccess,
  requestRoles
} from 'redux/actions/catalogsActions';
import { listProfiles } from 'services/users';
import { setProfilesToGroup } from '../../utils/helperActions';
import { getCatalogFromState } from './selectors';

function* requestProfilesWorker({ id }) {
  const profiles = yield select(state => getCatalogFromState(state, 'profiles'));
  //checamos si ya tenemos los datos cargados previamente
  if (typeof profiles !== 'undefined' && profiles.groups.length === 0) {
    yield put(requestRoles());
  } else if (typeof profiles === 'undefined') {
    try {
      const response = yield call(listProfiles, id);
      if (typeof response !== 'undefined' && response.status === 200) {
        let newGroup = setProfilesToGroup(id, profiles.groups, response.data.data);
        yield put(profilesSuccess(newGroup.current));
        yield put(rolesSuccess(newGroup.newArray));
      }
    } catch (e) {
      yield put(profilesFail());
    }
  } else {
    let exist = profiles.groups.filter(g => g.groupId === id);
    if (exist[0] && exist[0].hasOwnProperty('profiles')) {
      let roleWithProfiles = exist[0];
      yield put(rolesSuccess(profiles.groups));
      yield put(profilesSuccess({ ...roleWithProfiles }));
    } else {
      try {
        const response = yield call(listProfiles, id);
        if (typeof response !== 'undefined' && response.status === 200) {
          let newGroup = setProfilesToGroup(id, profiles.groups, response.data.data);
          yield put(profilesSuccess(newGroup.current));
          yield put(rolesSuccess(newGroup.newArray));
        }
      } catch (e) {
        yield put(profilesFail());
      }
    }
  }
}

export function* watchRequestRoles() {
  yield takeEvery(PROFILES_REQUEST, requestProfilesWorker);
}
