import { sendNotification } from "helpers/helperNotifications";
import { t } from "i18next";
import { call, put, takeEvery } from "redux-saga/effects";
import { REPORT_QUEUED_REQUEST, reportQueuedFailure } from "redux/actions/inventoryActions";
import { reportQueuedApiRequest } from "services/inventory";

export function* reportQueuedRequest(action) {
  const { payload } = action;
  const { data } = payload;
  try {
    const response = yield call(reportQueuedApiRequest, data);
    if (typeof response !== 'undefined' && response.status === 200) {
      sendNotification('success', t('on.report.queued'));
    } else {
      yield put(reportQueuedFailure());
    }
  } catch (_error) {
    console.log(_error);
    yield put(reportQueuedFailure());
  }
}

export function* watchReportQueuedRequest() {
  yield takeEvery(REPORT_QUEUED_REQUEST, reportQueuedRequest);
}