import { takeLatest, put, call } from 'redux-saga/effects';

import {
  PROFILE_INFO_REQUEST,
  profileInfoSuccess,
  profileInfoFail
} from 'redux/actions/userActions';
import { getMyProfileInfo } from 'services/users';
import instance from 'services/request';
import { responseOK } from 'utils';

export function* workProfileInfoRequest() {
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();
  try {
    const response = yield call(getMyProfileInfo, source.token);
    if (responseOK(response)) {
      yield put(profileInfoSuccess(response?.data?.data.fileContent || false));
    } else {
      yield put(profileInfoFail());
    }
  } catch (_error) {
    yield put(profileInfoFail());
  }
}

/* This is watching action REQUEST_FAVORITE_CASE_PHOTO, whenever is executed it starts
  orchestrating the sideEffects   */
export function* watchProfileInfo() {
  yield takeLatest(PROFILE_INFO_REQUEST, workProfileInfoRequest);
}
