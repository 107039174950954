import { takeLatest, put, call, select, takeEvery } from 'redux-saga/effects';
import {
  PICS_INVENTORY_CHECKIN_FAV,
  picsInventorySet,
  picsInventorySetBlockRequest,
  PICS_INVENTORY_INCOMING_REQUEST,
  PICS_INVENTORY_OUT_REQUEST,
  picsInventoryIncomingFail,
  PICS_INVENTORY_FAVORITE_REQUEST,
  picsInventoryFavoriteSuccess,
  picsInventoryFavoriteFail,
  picsInventoryCheckInFav
} from 'redux/actions/picsActions';
import { orderArrayObjects } from 'utils';
import {
  updateEnabledTransfer,
  favoritePicUpdate
} from 'modules/UpdateVehicleEntries/redux-sagas/actions';
import {
  getInventoryPhotos,
  getInventoryIncomingDetail,
  getInventoryPhotosCheckout,
  getTotalFavorite
} from 'redux/sagas/selectors';
import { responseOK } from 'utils';
import { getPhotosInventoryIncoming, markAsFavorite } from 'services/inventoryIncoming';
import instance from 'services/request';
import { sendNotification } from 'helpers/helperNotifications';
import i18next from 'i18next';

const helperFav = (photos, fileId, setAll = false, checked = false) => {
  let withoutFind;
  const found = photos.data.find(item => item.fileId === fileId);
  if (found) {
    withoutFind = photos.data.filter(item => item.fileId !== fileId);
    if(!setAll){
      if (found.favorite === false || found.asFavorite === false) {
        withoutFind = [...withoutFind, { ...found, asFavorite: true, favorite: true, isCover: false }];
      } else if (found.favorite === true || found.asFavorite === true) {
        withoutFind = [...withoutFind, { ...found, asFavorite: false, favorite: false }];
      }
    }else{
      withoutFind = [...withoutFind, { ...found, asFavorite: checked, favorite: checked, isCover: !checked  ? false : found.isCover }];
    }
  }

  if (withoutFind && withoutFind.length > 0) return withoutFind.sort(orderArrayObjects('fileId'));
};

function* blockNextRequest() {
  yield put(picsInventorySetBlockRequest(true));
}

function* setFavoriteStatus(action) {
  const { payload } = action;
  const { fileId, onFailFavorite, from, asFavorite, setAll } = payload;
  try {
    /** Check how many pics are favorites to let mark one more */
  const photos = yield select(state => getInventoryPhotos(state));
  const photosCheckout = yield select(state => getInventoryPhotosCheckout(state));

  let currentOnBD = yield select(state => getInventoryIncomingDetail(state));
  //let updateEntries = yield select(state => vehicleEntriesDetail(state));
  let getTotalFavoriteLocal = yield select(state => getTotalFavorite(state));
  let result = [];
  if (from === 'incoming') {
    result = yield call(helperFav, photos, fileId, setAll, asFavorite);
  } else if (from === 'outgoing') {
    result = yield call(helperFav, photosCheckout, fileId);
  } else {
    result = yield call(helperFav, photos, fileId, setAll, asFavorite);
  }

  let localFavorites = result?.filter(photo => photo.asFavorite);
  localFavorites = typeof localFavorites !== 'undefined' ? localFavorites : [];
  currentOnBD = typeof currentOnBD !== 'undefined' ? currentOnBD : 0;
  //updateEntries = typeof updateEntries !== 'undefined' ? updateEntries : 0;

  let totalFavorites = localFavorites?.length + currentOnBD;

  if (getTotalFavoriteLocal > 0) {
    let favoriteLocal = localFavorites?.length - (localFavorites?.length - 1);
    if (asFavorite) {
      totalFavorites = currentOnBD + getTotalFavoriteLocal - favoriteLocal;
      yield put(favoritePicUpdate({ totalFavorites }));
    } else {
      totalFavorites = favoriteLocal + currentOnBD + getTotalFavoriteLocal;
      yield put(favoritePicUpdate({ totalFavorites }));
    }
  }

  if (totalFavorites <= 15) {
    if (from === 'incoming') {
      yield put(
        picsInventorySet({ data: result.sort(orderArrayObjects('order')), key: 'incoming' })
      );
    } else if (from === 'outgoing') {
      yield put(picsInventorySet({ data: result, key: 'checkout' }));
    } else {
      yield put(picsInventorySet({ data: result, key: 'incoming' }));
    }

    yield put(picsInventorySetBlockRequest(true));
  } else {
    if (onFailFavorite) {
      yield onFailFavorite(false);
    }
    sendNotification('error', i18next.t('MSG_090'));
  }
  } catch (error) {
    console.log(error);
  }
  
}

function* workertPicsRequestIncoming(action) {
  const { payload } = action;
  const { inventoryId, type } = payload;
  let dataToSave = [];
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();

  try {
    const response = yield call(getPhotosInventoryIncoming, {
      inventoryId,
      type,
      cancelToken: source.token
    });
    if (responseOK(response)) {
      /** Formats each photo to before saving the response to the store
       * in this case adds favorite and checked atributtes to support
       * functionality of UniversePT pics
       */
      let order = 1;
      response.data.data.pics.map(pic => {
        const formatedPicToSave = {
          ...pic,
          order: order++
        };
        dataToSave.push(formatedPicToSave);
      });
      yield put(updateEnabledTransfer({ data: response.data.data.enabledTransfer }));

      if (type === '39') {
        yield put(
          picsInventorySet({ data: dataToSave.sort(orderArrayObjects('order')), key: 'incoming' })
        );
        // const photos = yield select(state => getInventoryPhotos(state));

        // let localFavorites = photos.data?.filter(photo => photo.asFavorite);
        // localFavorites = typeof localFavorites !== 'undefined' ? localFavorites : [];
        // let getTotalFavoriteLocal = yield select(state => getTotalFavorite(state));
        // let totalFavorites = 0;
        // totalFavorites = getTotalFavoriteLocal - localFavorites?.length;
        // yield put(favoritePicUpdate({ totalFavorites }));
      }
    } else {
      yield put(picsInventoryIncomingFail());
    }
  } catch (_error) {
    yield put(picsInventoryIncomingFail());
  }
}
function* workertPicsRequestOut(action) {
  const { payload } = action;
  const { inventoryId, type } = payload;
  let dataToSave = [];
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();
  try {
    const response = yield call(getPhotosInventoryIncoming, {
      inventoryId,
      type,
      cancelToken: source.token
    });
    if (responseOK(response)) {
      /** Formats each photo to before saving the response to the store
       * in this case adds favorite and checked atributtes to support
       * functionality of UniversePT pics
       */
      response.data.data.pics.map(pic => {
        const formatedPicToSave = {
          ...pic
        };
        dataToSave.push(formatedPicToSave);
      });

      if (type === '40') {
        yield put(
          picsInventorySet({ data: dataToSave.sort(orderArrayObjects('fileId')), key: 'checkout' })
        );
      }
    } else {
      yield put(picsInventoryIncomingFail());
    }
  } catch (_error) {
    yield put(picsInventoryIncomingFail());
  }
}

function* workerFavoriteInventoryPic(action) {
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();
  const { payload } = action;

  const { inventoryId, fileId, onFailFavorite, from, asFavorite, onSuccess = null, fromAll= false, setAll = false } = payload;
  // const photos = yield select(state => getInventoryPhotos(state));
  try {
    let getTotalFavoriteLocal = yield select(state => getTotalFavorite(state));
    if (getTotalFavoriteLocal <= 14 || !getTotalFavoriteLocal || asFavorite) {
      const response = yield call(markAsFavorite, {
        inventoryId,
        fileId,
        cancelToken: source.token,
        asFavorite,
        fromAll
      });
      if (responseOK(response)) {
        console.log(fromAll, asFavorite);
        yield put(picsInventoryCheckInFav({ fileId, from, asFavorite, fromAll, setAll }));
        if(onSuccess)
          onSuccess();
        yield put(picsInventoryFavoriteSuccess());
      } else {
        if (onFailFavorite) {
          yield onFailFavorite(false);
        }
        yield put(picsInventoryFavoriteFail());
      }
    } else {
      yield put(picsInventoryFavoriteFail());
      if (onFailFavorite) {
        yield onFailFavorite(false);
      }
      sendNotification('error', i18next.t('MSG_090'));
    }
  } catch (_error) {
    if (onFailFavorite) {
      yield onFailFavorite(false);
    }
    yield put(picsInventoryFavoriteFail());
  }
}

export function* watchFavoriteAudatexPic() {
  yield takeEvery(PICS_INVENTORY_CHECKIN_FAV, setFavoriteStatus);
}

export function* watchIncomingInventoryPics() {
  yield takeLatest(PICS_INVENTORY_INCOMING_REQUEST, workertPicsRequestIncoming);
}

export function* watchOutInventoryPics() {
  yield takeLatest(PICS_INVENTORY_OUT_REQUEST, workertPicsRequestOut);
}

export function* watchFavoriteInventoryPic() {
  yield takeEvery(PICS_INVENTORY_FAVORITE_REQUEST, workerFavoriteInventoryPic);
}
