import { takeLatest, put, call, select } from 'redux-saga/effects';
import { REQUEST_LANGUAGES, languagesSuccess, languagesFail } from 'redux/actions/catalogsActions';
import { listLanguages } from 'services/catalogs';
import { getCatalogFromState } from './selectors';

function* requestLanguagesAsync() {
  const languages = yield select(state => getCatalogFromState(state, 'languages'));

  if (typeof languages !== 'undefined' && languages.length > 0) {
    return languages;
  }

  try {
    const response = yield call(listLanguages);
    if (typeof response !== 'undefined' && response.status === 200) {
      yield put(languagesSuccess(response.data.data));
    } else {
      yield put(languagesFail());
    }
  } catch (error) {
    yield put(languagesFail());
    console.log('error', error);
  }
}

/* This is watching action REQUEST_LANGUAGES, whenever is executed it starts
orchestrating the sideEffects   */
export function* watchRequestLanguages() {
  yield takeLatest(REQUEST_LANGUAGES, requestLanguagesAsync);
}