import { takeLatest, put, call, select, takeEvery } from 'redux-saga/effects';
import { orderArrayObjects } from 'utils';
import {
  REQUEST_FAVORITE_CASE_PHOTO,
  favoriteCaseImagesSuccess,
  favoriteCaseImagesFail,
  markAsFavorite
} from 'redux/actions/caseActions';
import { markAsFavoriteDelivered, markAsFavoriteCollected } from 'redux/actions/transferActions';
import { favCasePic } from 'services/cases';
import { picsInventoryFavoriteRequest } from 'redux/actions/picsActions';
import { getPhotos, getPhotosDelivered, getPhotosCollected } from 'redux/sagas/selectors';

const helper = (photos, fileId, setAll = false, checked = false) => {
  let withoutFind;
  const found = photos.data.find(item => item.id === fileId);
  if (found) {
    withoutFind = photos.data.filter(item => item.id !== fileId);

    if(!setAll){
      if (found.favorite) {
        withoutFind = [...withoutFind, { ...found, favorite: false, isCover: false }];
      } else {
        withoutFind = [...withoutFind, { ...found, favorite: true }];
      }
    }else{
      withoutFind = [...withoutFind, { ...found, favorite: checked, isCover: !checked  ? false : found.isCover }];
    }
  }
  if (withoutFind) return withoutFind.sort(orderArrayObjects('order'));
};

function* setFavoriteCasePicAsync(action) {
  const { payload } = action;
  const { fileId, caseId, onSuccessFavorite, imageType, inventoryId, asFavorite, fromAll, setAll } = payload;
  let result;
  try {
    const response = yield call(favCasePic, { caseId: caseId, fileId: fileId, asFavorite, fromAll });
    const photos = yield select(state => getPhotos(state));
    const recolectedPhotos = yield select(state => getPhotosCollected(state));
    const deliveredPhotos = yield select(state => getPhotosDelivered(state));
    if (typeof response !== 'undefined' && response.status === 200) {
      if (imageType === 'CASE') {
        result = yield call(helper, photos, fileId, setAll, asFavorite);
        yield put(markAsFavorite(result));
      } else if (imageType === 'COLLECTED') {
        result = yield call(helper, recolectedPhotos, fileId, setAll, asFavorite);
        yield put(markAsFavoriteCollected(result));
      } else if (imageType === 'DELIVERY') {
        result = yield call(helper, deliveredPhotos, fileId, setAll, asFavorite);
        yield put(markAsFavoriteDelivered(result));
      } else if (imageType === 'INVENTORY') {
        let params = { fileId, inventoryId };
        yield put(picsInventoryFavoriteRequest({ params }));
      }
      yield put(favoriteCaseImagesSuccess());
      yield onSuccessFavorite();
    } else {
      yield put(favoriteCaseImagesFail());
    }
  } catch (_error) {
    yield put(favoriteCaseImagesFail());
  }
}

/* This is watching action REQUEST_FAVORITE_CASE_PHOTO, whenever is executed it starts
orchestrating the sideEffects   */
export function* watchRequestCountries() {
  yield takeEvery(REQUEST_FAVORITE_CASE_PHOTO, setFavoriteCasePicAsync);
}
