import { takeLatest, takeEvery, put, call } from 'redux-saga/effects';
import {
  POST_TRANSFER_PHOTO_REQUEST,
  postTransferPhotoSuccess,
  postTransferPhotoFail,
  addPhotoDelivered,
  addPhoto
} from 'redux/actions/transferActions';
import { postTransferPhoto } from 'services/transfers';
import { sendNotification } from 'helpers/helperNotifications';

function* postTransferPhotoAsync(action) {
  const { payload } = action;
  const { requestData, photoType, movementId } = payload;
  try {
    const response = yield call(postTransferPhoto, movementId, requestData);
    if (typeof response !== 'undefined' && response.status === 200) {
      const formatedData = {
        id: response.data.data,
        name: requestData.fileName,
        content: requestData.content
      };
      if (photoType === 'COLLECTED') {
        yield put(addPhoto(formatedData));
      } else if (photoType === 'DELIVERY') {
        yield put(addPhotoDelivered(formatedData));
      } else {
        console.error('photoType not valid ', photoType, ' Did you mean that ? ');
      }
      yield put(postTransferPhotoSuccess());
      yield sendNotification('success', response.data.message);
    } else {
      yield put(postTransferPhotoFail());
    }
  } catch (error) {
    yield put(postTransferPhotoFail());
    console.error('error', error);
  }
}

/* This is watching action REQUEST_UPLOAD_CASE_PHOTO, whenever is executed it starts
orchestrating the sideEffects   */
export function* watchRequestCountries() {
  yield takeEvery(POST_TRANSFER_PHOTO_REQUEST, postTransferPhotoAsync);
}
