import { takeLatest, put, call } from 'redux-saga/effects';
import { LAST_PICS_REQUEST } from 'redux/actions/picsActions';
import { caseImagesSuccess, caseImagesFail, setCaseImagesLoading } from 'redux/actions/caseActions';
import {
  setDeliveredPicsLoading,
  setRecolectionPicsLoading,
  deliveredPicsSuccess,
  deliveredPicsFail,
  recolectionPicsSuccess,
  recolectionPicsFail
} from 'redux/actions/transferActions';
import { favCountSuccess } from 'redux/actions/picsActions';
import { getLastPics } from 'services/cases';
import { orderArrayObjects } from 'utils';

const helperOrder = photos => {
  let dataToSave = [];
  let order = 1;
  photos.map(pic => {
    const formatedPicToSave = {
      ...pic,
      order: order++
    };
    dataToSave.push(formatedPicToSave);
  });

  return dataToSave;
};

function* requestLastPicsAsync(action) {
  // TODO: Set isLoading for corresponding photo spaces
  const { payload } = action;
  const { caseId } = payload;
  yield put(setCaseImagesLoading(true));
  yield put(setRecolectionPicsLoading(true));
  yield put(setDeliveredPicsLoading(true));
  try {
    const response = yield call(getLastPics, caseId);
    if (typeof response !== 'undefined' && response.status === 200) {
      const resultImagesCase = yield call(helperOrder, response.data.data.case);
      yield put(caseImagesSuccess(resultImagesCase.sort(orderArrayObjects('order'))));
      const resultImagesCollected = yield call(helperOrder, response.data.data.collected);
      yield put(
        recolectionPicsSuccess({
          data: resultImagesCollected.sort(orderArrayObjects('order')),
          movementId: response.data.data.collectedActionId
        })
      );
      const resultImagesDelivered = yield call(helperOrder, response.data.data.delivered);
      yield put(
        deliveredPicsSuccess({
          data: resultImagesDelivered.sort(orderArrayObjects('order')),
          movementId: response.data.data.collectedActionId
        })
      );
      yield put(favCountSuccess(response.data.data.favorites));
      if (action.payload.onSucces) {
        let casePhotos = response.data.data.case.sort(orderArrayObjects('order'));
        let recoPhotos = response.data.data.collected.sort(orderArrayObjects('order'));
        let deliPhotos = response.data.data.delivered.sort(orderArrayObjects('order'));
        casePhotos = casePhotos.slice(0, 5);
        recoPhotos = recoPhotos.slice(0, 5);
        deliPhotos = deliPhotos.slice(0, 5);
        const allInOne = [...casePhotos, ...recoPhotos, ...deliPhotos];
        action.payload.onSucces(allInOne);
      }
    } else {
      yield put(caseImagesFail());
      yield put(deliveredPicsFail());
      yield put(recolectionPicsFail());
    }
  } catch (error) {
    yield put(caseImagesFail());
    console.error('error', error);
  }
}

/* This is watching action REQUEST_CASE_IMAGES, whenever is executed it starts
orchestrating the sideEffects   */
export function* watchRequestLastPics() {
  yield takeLatest(LAST_PICS_REQUEST, requestLastPicsAsync);
}
