import { call, put, select, takeEvery } from "redux-saga/effects";
import { REQUEST_COVER_PHOTO, coverPhotoFail, changeCasePhotos } from "redux/actions/caseActions";
import { coverCasePic } from "services/cases";
import { getInventoryPhotos, getInventoryPhotosCheckout, getPhotos, getPhotosCollected, getPhotosDelivered } from "./selectors";
import { responseOK } from "utils";
import { changeTrasnferPics } from "redux/actions/transferActions";
import { picsInventorySet } from "redux/actions/picsActions";

function* resetAllCovers (fileId){
    const casePhotos = yield select(state => getPhotos(state));
    const recolectedPhotos = yield select(state => getPhotosCollected(state));
    const deliveredPhotos = yield select(state => getPhotosDelivered(state));
    const checkInPhotos = yield select(state => getInventoryPhotos(state));
    const checkOutPhotos = yield select(state => getInventoryPhotosCheckout(state));
    
    //reset all pics in case photos
    const newCasePhotos = casePhotos.data.map(item => {
        if(item.id === fileId)
            return { ...item};
        else
            return { ...item, isCover: false };
    });
    yield put(changeCasePhotos(newCasePhotos));

    const newRecolectedPhotos = recolectedPhotos.data.map(item => {
        if(item.id === fileId)
            return { ...item};
        else
            return { ...item, isCover: false };
    });

    const newDeliveredPhotos = deliveredPhotos.data.map(item => {
        if(item.id === fileId)
            return { ...item};
        else
            return { ...item, isCover: false };
    });

    yield put(changeTrasnferPics({newRecolectedPhotos, newDeliveredPhotos}));

    const newCheckInPhotos = checkInPhotos.data.map(item => {
        if(item.fileId === fileId)
            return { ...item};
        else
            return { ...item, isCover: false };
    });

    yield put(picsInventorySet({data: newCheckInPhotos, key : 'incoming'}));

    const newCheckOutPhotos = checkOutPhotos.data.map(item => {
        if(item.fileId === fileId)
            return { ...item};
        else
            return { ...item, isCover: false };
    });

    yield put(picsInventorySet({data: newCheckOutPhotos, key : 'checkout'}));

};

function* updateCover (fileId){
    const casePhotos = yield select(state => getPhotos(state));
    const recolectedPhotos = yield select(state => getPhotosCollected(state));
    const deliveredPhotos = yield select(state => getPhotosDelivered(state));
    const checkInPhotos = yield select(state => getInventoryPhotos(state));
    const checkOutPhotos = yield select(state => getInventoryPhotosCheckout(state));

    const foundCase = casePhotos.data?.find(item => item.id === fileId);
    let newCasePhotos;
    if (foundCase) {
        newCasePhotos = casePhotos.data.map(item => {
            if (item.id === fileId) {
                return { ...item, isCover: !item.isCover };
            } else {
                return { ...item, isCover: false };
            }
        });

        yield put(changeCasePhotos(newCasePhotos));
    }

    const foundRecolected = recolectedPhotos?.data.find(item => item.id === fileId);
    let newRecolectedPhotos;
    if (foundRecolected) {
        newRecolectedPhotos = recolectedPhotos.data.map(item => {
            if (item.id === fileId) {
                return { ...item, isCover: !item.isCover };
            } else {
                return { ...item, isCover: false };
            }
        });

        yield put(changeTrasnferPics({ newRecolectedPhotos, newDeliveredPhotos :deliveredPhotos.data}));
    }

    const foundDelivered = deliveredPhotos?.data.find(item => item.id === fileId);
    let newDeliveredPhotos;
    if (foundDelivered) {
        newDeliveredPhotos = deliveredPhotos.data.map(item => {
            if (item.id === fileId) {
                return { ...item, isCover: !item.isCover };
            } else {
                return { ...item, isCover: false };
            }
        });

        yield put(changeTrasnferPics({newRecolectedPhotos :recolectedPhotos.data, newDeliveredPhotos}));
    }

    const foundCheckIn = checkInPhotos?.data.find(item => item.fileId === fileId);
    let newCheckInPhotos;
    if (foundCheckIn) {
        newCheckInPhotos = checkInPhotos.data.map(item => {
            if (item.fileId === fileId) {
                return { ...item, isCover: !item.isCover };
            } else {
                return { ...item, isCover: false };
            }
        });

        yield put(picsInventorySet({data: newCheckInPhotos, key : 'incoming'}));
    }

    const foundCheckOut = checkOutPhotos?.data.find(item => item.fileId === fileId);
    let newCheckOutPhotos;
    if (foundCheckOut) {
        newCheckOutPhotos = checkOutPhotos.data.map(item => {
            if (item.fileId === fileId) {
                return { ...item, isCover: !item.isCover };
            } else {
                return { ...item, isCover: false };
            }
        });

        yield put(picsInventorySet({data: newCheckOutPhotos, key : 'checkout'}));
    }

};

function* setCoverPic(action) {
    try {
        const { payload } = action;
        const { fileId } = payload;
        const response = yield call(coverCasePic, payload);
        if(responseOK(response)){
            yield call(resetAllCovers, fileId);
            yield call(updateCover, fileId);
        }else{
            yield put(coverPhotoFail);
        }
    } catch (error) {
        console.log('saga error', error);
        yield put(coverPhotoFail);
    }
 
};

export function* watchSetCoverPic() {
  yield takeEvery(REQUEST_COVER_PHOTO, setCoverPic);
}