import { call, put, takeLatest } from "redux-saga/effects";
import { REQUEST_REGIMES, regimesFail, regimesSuccess } from "redux/actions/catalogsActions";
const { listRegimes } = require("services/catalogs");


function* requestRegimesAsync(action){
    try {
        const {payload} = action;
        const response = yield call(listRegimes, payload);
        if(typeof response !== 'undefined' && response.status === 200){
            yield put(regimesSuccess(response.data.data));
        } else {
            yield put(regimesFail());
        }
    } catch (error) {
        yield put(regimesFail());        
    }
}

export function* watchRequestRegimes(){
    yield takeLatest(REQUEST_REGIMES, requestRegimesAsync);
};