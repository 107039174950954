import { put, takeEvery, call } from 'redux-saga/effects';
import {
  CHANGE_PASSWORD_REQUEST,
  changePasswordSuccess,
  changePasswordFail
} from 'redux/actions/userActions';
import { refreshToken } from 'redux/actions/authActions';
import { getChangePassword } from 'services/auth';

export function* workChangePasswordAsync(action) {
  const { payload } = action;
  const { onSuccess, onEqual, dataRequest } = payload;
  try {
    const response = yield call(getChangePassword, dataRequest);
    if (typeof response !== 'undefined' && response.status === 200) {
      yield put(changePasswordSuccess());
      yield onSuccess(response.data?.message);
      yield put(refreshToken({ token: localStorage.tkn, method: 'PUT' }));
    } else {
      yield onEqual();
      yield put(changePasswordFail());
    }
  } catch (error) {
    console.error('error', error);
    yield put(changePasswordFail());
  }
}

export function* watchChangePass() {
  yield takeEvery(CHANGE_PASSWORD_REQUEST, workChangePasswordAsync);
}
