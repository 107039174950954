import { takeLatest, put, call } from 'redux-saga/effects';
import { REQUEST_MARKETSALL, marketsSuccess, marketsFail } from 'redux/actions/catalogsActions';
import { listMarketsall } from 'services/catalogs';

function* requestMarketsAllAsync() {
  try {
    const response = yield call(listMarketsall);
    if (typeof response !== 'undefined' && response.status === 200) {
      yield put(marketsSuccess(response.data.data));
    } else {
      yield put(marketsFail());
    }
  } catch (error) {
    yield put(marketsFail());
    console.error('error', error);
  }
}
export function* watchRequestAllMarkets() {
  yield takeLatest(REQUEST_MARKETSALL, requestMarketsAllAsync);
}
