import { takeLatest, takeEvery, put, call, select } from 'redux-saga/effects';
import {
  REQUEST_UPLOAD_CASE_PHOTO,
  uploadCaseImagesSuccess,
  uploadCaseImagesFail,
  addPhoto
} from 'redux/actions/caseActions';
import { uploadCasePic } from 'services/cases';
import { sendNotification } from 'helpers/helperNotifications';
import { getOrderCasePhoto } from './selectors';

function* uploadCasePicAsync(action) {
  const { payload } = action;
  const { requestData } = payload;
  try {
    const response = yield call(uploadCasePic, requestData);
    let photos = yield select(getOrderCasePhoto);
    let orderAdd = photos.length ? photos[photos.length - 1].order : 0;
    if (typeof response !== 'undefined' && response.status === 200) {
      const formatedData = {
        id: response.data.data,
        name: requestData.name,
        content: requestData.content,
        order: ++orderAdd
      };
      yield put(addPhoto(formatedData));
      yield sendNotification('success', response.data.message);
      yield put(uploadCaseImagesSuccess());
    } else {
      if (response.status === 400) {
        yield sendNotification('error', response.data.message);
      }
      yield put(uploadCaseImagesFail());
    }
  } catch (error) {
    yield put(uploadCaseImagesFail());
    // eslint-disable-next-line no-console
    console.log('error', error);
  }
}

/* This is watching action REQUEST_UPLOAD_CASE_PHOTO, whenever is executed it starts
orchestrating the sideEffects   */
export function* watchRequestCountries() {
  yield takeEvery(REQUEST_UPLOAD_CASE_PHOTO, uploadCasePicAsync);
}
