import { takeLatest, put, call } from 'redux-saga/effects';
import { REQUEST_JOBS, jobsSuccess, jobsFail } from 'redux/actions/catalogsActions';
import { listJobs } from 'services/catalogs';

function* requestJobsAsync(action) {
  const { payload } = action;
  try {
    const response = yield call(listJobs, payload);
    if (typeof response !== 'undefined' && response.status === 200) {
      yield put(jobsSuccess(response.data.data));
    } else {
      yield put(jobsFail());
    }
  } catch (error) {
    yield put(jobsFail());
    yield console.error('error', error);
  }
}

/* This is watching action REQUEST_COUNTRIES, whenever is executed it starts
orchestrating the sideEffects   */
export function* watchRequestJobs() {
  yield takeLatest(REQUEST_JOBS, requestJobsAsync);
}
