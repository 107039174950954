import { takeLatest, put, call } from 'redux-saga/effects';
import {
  DELETE_TRANSFER_PHOTO_REQUEST,
  deleteTransferPhotoSuccess,
  deleteTransferPhotoFail,
  deletePhotoDelivered,
  deletePhoto
} from 'redux/actions/transferActions';
import { deletePic } from 'services/transfers';
import { sendNotification } from 'helpers/helperNotifications';

export function* postTransferPhotoAsync(action) {
  const { payload } = action;
  const { fileId, movementId, photoType } = payload;
  try {
    const response = yield call(deletePic, movementId, fileId);
    if (typeof response !== 'undefined' && response.status === 200) {
      if (photoType === 'COLLECTED') {
        yield put(deletePhoto(fileId));
      } else if (photoType === 'DELIVERY') {
        yield put(deletePhotoDelivered(fileId));
      } else {
        console.warn(
          'photoType not valid ',
          photoType,
          ' Did you mean that. Should be COLLECTED or DELIVERY  ? '
        );
      }
      yield sendNotification('success', response.data.message);
      yield put(deleteTransferPhotoSuccess());
    } else {
      yield put(deleteTransferPhotoFail());
    }
  } catch (error) {
    console.error('error', error);
    yield put(deleteTransferPhotoFail());
  }
}

/* This is watching action REQUEST_UPLOAD_CASE_PHOTO, whenever is executed it starts
orchestrating the sideEffects   */
export function* watchRequestCountries() {
  yield takeLatest(DELETE_TRANSFER_PHOTO_REQUEST, postTransferPhotoAsync);
}
