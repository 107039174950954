import { takeLatest, put, call, cancelled } from 'redux-saga/effects';
import { SEARCH_REQUEST, searchSuccess, searchFail, saveSearch } from 'redux/actions/searchActions';
import { responseOK } from 'utils';
import instance from 'services/request';
import { picsInventoryClean } from 'redux/actions/picsActions';

export function* requestSearch(action) {
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();
  try {
    const { payload } = action;

    const { params, searchOn, onSearchSuccess, onFail } = payload;
    yield put(saveSearch(params));
    const response = yield call(searchOn, { params, cancelToken: source.token });
    yield put(picsInventoryClean());
    if (responseOK(response)) {
      yield put(searchSuccess(params));
      yield onSearchSuccess(response.data.data);
    } else {
      if (onFail) {
        yield onFail(response);
      }
      yield put(searchFail());
    }
  } catch (_e) {
    yield put(searchFail());
  } finally {
    if (yield cancelled()) {
      source.cancel('cancelled REQUEST');
      // yield put(searchFail());
    }
  }
}

/* This is watching action , whenever is executed it starts*/

export function* watchRequest() {
  yield takeLatest(SEARCH_REQUEST, requestSearch);
}
