import { takeEvery, put, call, select } from 'redux-saga/effects';
import { ROLES_GROUP_REQUEST, rolesSuccess, rolesFail } from 'redux/actions/catalogsActions';
import { listRoles } from 'services/users';
import { getCatalogFromState } from './selectors';

function* requestRolesWorker() {
  const profiles = yield select(state => getCatalogFromState(state, 'profiles'));
  if (profiles && typeof profiles.groups === 'undefined') {
    try {
      const response = yield call(listRoles);
      if (typeof response !== 'undefined' && response.status === 200) {
        yield put(rolesSuccess(response.data.data));
        return response.data.data;
      } else {
        yield put(rolesFail());
      }
    } catch (_error) {
      yield put(rolesFail());
    }
  } else {
    yield put(rolesSuccess(profiles.groups));
    return profiles;
  }
}

export function* watchRequestRoles() {
  yield takeEvery(ROLES_GROUP_REQUEST, requestRolesWorker);
}
