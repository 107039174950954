import { takeLatest, put, call } from 'redux-saga/effects';
import {
  REQUEST_UPDATE_DIVERSES,
  updateDiversesSuccess,
  updateDiversesFail
} from 'redux/actions/caseActions';
import { updateProductCase } from 'services/cases';
import { sendNotification } from 'helpers/helperNotifications';

function* requestUpdateDiverseCaseAsync(action) {
  const { payload } = action;
  const { requestData, onCaseUpdateSuccess } = payload;
  try {
    const response = yield call(updateProductCase, requestData);
    if (typeof response !== 'undefined' && response.status === 200) {
      yield put(updateDiversesSuccess(response.data.data));
      yield sendNotification('success', response.data.message);
      yield onCaseUpdateSuccess();
    } else {
      yield put(updateDiversesFail());
    }
  } catch (_error) {
    yield put(updateDiversesFail());
  }
}

/* This is watching action REQUEST_UPDATE_DIVERSES, whenever is executed it starts
orchestrating the sideEffects   */
export function* watchRequestCountries() {
  yield takeLatest(REQUEST_UPDATE_DIVERSES, requestUpdateDiverseCaseAsync);
}
