import { takeLatest, put, call } from 'redux-saga/effects';
import { TRANSFER_LAST_PICS_REQUEST } from 'redux/actions/picsActions';
import { caseImagesSuccess, caseImagesFail, setCaseImagesLoading } from 'redux/actions/caseActions';
import {
  setDeliveredPicsLoading,
  setRecolectionPicsLoading,
  deliveredPicsSuccess,
  deliveredPicsFail,
  recolectionPicsSuccess,
  recolectionPicsFail
} from 'redux/actions/transferActions';
import { getLastPics } from 'services/cases';
import { orderArrayObjects } from 'utils';

function* requestLastPicsTransferAsync(action) {
  const { payload } = action;
  const { caseId, transferId } = payload;
  yield put(setCaseImagesLoading(true));
  yield put(setRecolectionPicsLoading(true));
  yield put(setDeliveredPicsLoading(true));
  try {
    const response = yield call(getLastPics, caseId, transferId);
    if (typeof response !== 'undefined' && response.status === 200) {
      yield put(caseImagesSuccess(response.data.data.case.sort(orderArrayObjects('name'))));
      yield put(
        recolectionPicsSuccess({
          data: response.data.data.collected.sort(orderArrayObjects('name')),
          movementId: response.data.data.collectedActionId
        })
      );
      yield put(
        deliveredPicsSuccess({
          data: response.data.data.delivered.sort(orderArrayObjects('name')),
          movementId: response.data.data.deliveredActionId
        })
      );
    } else {
      yield put(caseImagesFail());
      yield put(deliveredPicsFail());
      yield put(recolectionPicsFail());
    }
  } catch (error) {
    yield put(caseImagesFail());
    console.log('error', error);
  }
}

/* This is watching action REQUEST_CASE_IMAGES, whenever is executed it starts
orchestrating the sideEffects   */
export function* watchRequestLastPics() {
  yield takeLatest(TRANSFER_LAST_PICS_REQUEST, requestLastPicsTransferAsync);
}
