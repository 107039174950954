import { takeLatest, put, call, cancelled, select, takeEvery } from 'redux-saga/effects';
import {
  PAYMENT_DETAIL_REQUEST,
  paymentDetailSuccess,
  paymentDetailFail,
  VALIDATE_PAYMENT_FILE_REQUEST,
  validatePaymentFileSuccess,
  validatePaymentFileFail,
  VALIDATE_PAYMENT_HISTORY_REQUEST,
  validatePaymentHistorySuccess,
  validatePaymentHistoryFail,
  CANCEL_PAYMENT_REQUEST,
  cancelPaymentSuccess,
  cancelPaymentFail
} from 'redux/actions/paymentsActions';
import { responseOK } from 'utils';
import {
  paymentDetail,
  validatePaymentFile,
  validatePaymentHistory,
  cancelPayment,
  cancelSale
} from 'services/awardings';
import { getFromStore } from '../selectors';

import instance from 'services/request';
import { getVouchersSuccess } from 'redux/actions/buyersActions';
import { sendNotification } from 'helpers/helperNotifications';
import i18next from 'i18next';

export function updateReference(currentReferences, paymentReferenceId, fileId, evaluation) {
  let newState = currentReferences;
  let reference = currentReferences.filter(
    paymentReference => paymentReference.paymentReferenceId === paymentReferenceId
  );
  let excludeReferences = currentReferences.filter(
    paymentReference => paymentReference.paymentReferenceId !== paymentReferenceId
  );
  if (reference.length > 0) {
    let newFiles = [];
    let fileFounded = reference[0].files.filter(file => file.id === fileId);
    let excludeFiles = reference[0].files.filter(file => file.id !== fileId);
    if (fileFounded.length > 0) {
      fileFounded[0].statusSemaphore = evaluation ? 1 : 3;
      fileFounded[0].fileStatusId = evaluation ? 2 : 3;
      newFiles = [...excludeFiles, { ...fileFounded }];
      reference.files = newFiles;
    }
    newState = [...excludeReferences, ...reference];
  }
  return newState;
}

export function updateVouchers(currentVoucher, fileId, evaluation) {

  const voucher = currentVoucher.map(i => {
    if (i.fileId === fileId) {
      return {
        ...i,
        statusId: evaluation ? 2 : 3
      }
    }
    else {
      return i
    }
  });

  return voucher;
}

export function* requestPaymentDetail(action) {
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();
  try {
    const { payload } = action;
    const { awardId } = payload;

    const response = yield call(paymentDetail, awardId, source.token);
    if (responseOK(response)) {
      //yield put(searchSuccess(params));
      yield put(paymentDetailSuccess(response.data.data));
      if (response?.data?.data?.sendValidate) {
        sendNotification('info', i18next.t('MSG_141'));
      }
      //yield onSearchSuccess(response.data)
    } else {
      yield put(paymentDetailFail());
    }
  } catch (e) {
    yield put(paymentDetailFail());
  } finally {
    if (yield cancelled()) {
      source.cancel('cancelled REQUEST');
    }
  }
}

export function* requestValidateFile(action) {
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();
  const currentReferences = yield select(state => getFromStore(state, 'payments', 'paymentDetail'));
  
  const currentVoucher = yield select(state => getFromStore(state, 'buyers', 'vouchers'));

  try {
    const { payload } = action;
    const { data, onSuccess, onFail } = payload;

    const response = yield call(validatePaymentFile, data, source.token);
    if (responseOK(response)) {      
      const stateUpdated = yield updateReference(
        currentReferences.references,
        data.paymentReferenceId,
        data.fileId,
        data.evaluation
      );

      const updatedVouchers = updateVouchers(currentVoucher.data, data.fileId, data.evaluation);
      yield put(getVouchersSuccess(updatedVouchers));

      yield put(validatePaymentFileSuccess({ data: stateUpdated }));
      yield onSuccess(response.data);
    } else {
      yield onFail();
      yield put(validatePaymentFileFail());
    }
  } catch (e) {
    yield put(validatePaymentFileFail());
  } finally {
    if (yield cancelled()) {
      source.cancel('cancelled REQUEST');
    }
  }
}

export function* requestHistoryValidations(action) {
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();

  try {
    const { payload } = action;
    const { referenceId, onSuccess, onFail } = payload;

    const response = yield call(validatePaymentHistory, referenceId, source.token);
    if (responseOK(response)) {
      yield put(validatePaymentHistorySuccess());
      yield onSuccess(response.data);
    } else {
      yield onFail();
      yield put(validatePaymentHistoryFail());
    }
  } catch (e) {
    yield put(validatePaymentHistoryFail());
  } finally {
    if (yield cancelled()) {
      source.cancel('cancelled REQUEST');
    }
  }
}

export function* requestcancelPayment(action) {
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();

  try {
    const { payload } = action;
    const { data, onSuccess, isCancelSale } = payload;
    let response;
    if (isCancelSale) {
      response = yield call(cancelSale, data, source.token);
    } else {
      response = yield call(cancelPayment, data.caseId, source.token, data.cancelReasonId);
    }

    if (responseOK(response)) {
      yield put(cancelPaymentSuccess());
      yield onSuccess(response.data.message);
    } else {
      yield put(cancelPaymentFail());
    }
  } catch (e) {
    yield put(cancelPaymentFail());
  } finally {
    if (yield cancelled()) {
      source.cancel('cancelled REQUEST');
    }
  }
}

export function* watchRequest() {
  yield takeLatest(PAYMENT_DETAIL_REQUEST, requestPaymentDetail);
}

export function* watchValidateFileRequest() {
  yield takeLatest(VALIDATE_PAYMENT_FILE_REQUEST, requestValidateFile);
}

export function* watchValidateHistoryRequest() {
  yield takeEvery(VALIDATE_PAYMENT_HISTORY_REQUEST, requestHistoryValidations);
}

export function* watchCancelPaymentRequest() {
  yield takeLatest(CANCEL_PAYMENT_REQUEST, requestcancelPayment);
}
