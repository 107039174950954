import { fork, all } from 'redux-saga/effects';
import * as authSagas from './authSaga';
import * as usersSagas from 'redux/sagas/users';
import * as userSagas from 'redux/sagas/user';
import * as countriesSagas from 'redux/sagas/countriesSagas';
import * as languagesSagas from 'redux/sagas/languagesSagas';
import * as regimesSagas from 'redux/sagas/regimesSagas';
import * as timeZonesSagas from 'redux/sagas/timeZonesSagas';
import * as searchCasesInsuredE from 'redux/sagas/searchCasesInsuredE';
import * as caseTypesSaga from 'redux/sagas/caseTypesSaga';
import * as caseStatusSaga from 'redux/sagas/caseStatusSaga';
import * as rolesSagas from 'redux/sagas/rolesSagas';
import * as profilesSaga from 'redux/sagas/profilesSaga';
import * as departamentsSagas from 'redux/sagas/departamentsSagas';
import * as jobsSagas from 'redux/sagas/jobsSagas';
import * as marketsSagas from 'redux/sagas/marketsSagas';
import * as marketsSagasAll from 'redux/sagas/marketsSagasAll';
import * as menuSagas from 'redux/sagas/menuSagas';
import * as caseImagesSagas from 'redux/sagas/caseImagesSagas';
import * as casesSagas from 'redux/sagas/cases';
import * as closeCaseSaga from 'redux/sagas/closeCaseSaga';
import * as uploadCasePicSaga from 'redux/sagas/uploadCasePicSaga';
import * as deleteCasePicSagas from 'redux/sagas/deleteCasePicSagas';
import * as updateCaseDiversesSagas from 'redux/sagas/updateCaseDiversesSagas';
import * as setFavCasePicSaga from 'redux/sagas/setFavCasePicSaga';
import * as adverts from 'redux/sagas/adverts';
import * as lastPicsSagas from 'redux/sagas/pics/lastPicsSagas';
import * as audatexPics from 'redux/sagas/pics/audatexPics';
import * as transferLastPicsSagas from 'redux/sagas/pics/transferLastPicsSagas';
import * as postTransferPicSaga from 'redux/sagas/pics/transfers/postTransferPicSaga';
import * as deleteTransferPicSaga from 'redux/sagas/pics/transfers/deleteTransferPicsSaga';
import * as downloadFile from 'redux/sagas/downloadFile';
import * as catalogsSagas from 'redux/sagas/catalogs';
import * as buyersSagas from 'redux/sagas/buyers/';
import * as attachmentsSagas from 'redux/sagas/attachmentsDocuments';
import * as awardingsSagas from 'redux/sagas/awardings';
import * as searchSagas from 'redux/sagas/search';
import * as paymentsSagas from 'redux/sagas/payments';
import * as filesSagas from 'redux/sagas/files';
import * as deliveriesSagas from 'redux/sagas/deliveries';
import * as createOneCase from '../../modules/Case/redux-saga/sagas/createOneCase';
import * as moduleTransfer from '../../modules/Transfer/redux-saga/sagas';
import * as catalogs2Sagas from '../sagas/catalogs2';
import * as transfers from 'redux/sagas/transfers';
import * as compensation from '../../modules/Compensation/redux-saga/sagas';
import * as inventory from '../../modules/Inventory/redux-saga/sagas';
import * as reports from 'modules/Reports/redux-saga/sagas/';
import * as vehiclesIncoming from 'modules/VehiclesInComings/redux-saga/sagas';
import * as vehicleIncomingDetails from 'modules/DetailsVehicleIncoming/redux-saga/sagas';
import * as vehicleIncomingRegister from 'modules/RegisterVehicleIncoming/redux-sagas/sagas';
import * as vehicleEntryDetails from 'modules/VehicleEntryDetails/redux-saga/sagas';
import * as inventoryPhotos from 'redux/sagas/pics/inventoryPhotos';
import * as updateVehicleEntries from 'modules/UpdateVehicleEntries/redux-sagas/sagas';
import * as registerOutgoingVehicle from 'modules/RegisterOutgoingVehicle/redux-saga/sagas';
import * as transferCaseDetail from 'modules/TransferDetails/redux-saga/sagas';
import * as updateCaseTransfer from 'modules/TransferUpdate/redux-saga/sagas';
import * as updateOutgoingVehicle from 'modules/UpdateOutgoingVehicle/redux-sagas/sagas';
import * as notifications from 'modules/Notifications/redux-saga/sagas';
import * as logs from 'modules/EventLog/redux-saga/sagas';
import * as setCoverPic from 'redux/sagas/setCoverPicSaga';
import * as cancerReasonSagas from 'redux/sagas/CancelReasonSaga';
import * as componentSagas from 'redux/sagas/componentSaga';
import * as caseDamageSagas from 'redux/sagas/casesDamage';
import * as inventorySagas from 'redux/sagas/inventory';

export default function* rootSaga() {
  yield all(
    [
      ...Object.values(authSagas),
      ...Object.values(usersSagas),
      ...Object.values(userSagas),
      ...Object.values(countriesSagas),
      ...Object.values(languagesSagas),
      ...Object.values(regimesSagas),
      ...Object.values(timeZonesSagas),
      ...Object.values(searchCasesInsuredE),
      ...Object.values(caseTypesSaga),
      ...Object.values(caseStatusSaga),
      ...Object.values(rolesSagas),
      ...Object.values(profilesSaga),
      ...Object.values(departamentsSagas),
      ...Object.values(jobsSagas),
      ...Object.values(marketsSagas),
      ...Object.values(marketsSagasAll),
      ...Object.values(menuSagas),
      ...Object.values(caseImagesSagas),
      ...Object.values(casesSagas),
      ...Object.values(closeCaseSaga),
      ...Object.values(uploadCasePicSaga),
      ...Object.values(deleteCasePicSagas),
      ...Object.values(updateCaseDiversesSagas),
      ...Object.values(setFavCasePicSaga),
      ...Object.values(catalogsSagas),
      ...Object.values(adverts),
      ...Object.values(lastPicsSagas),
      ...Object.values(transferLastPicsSagas),
      ...Object.values(postTransferPicSaga),
      ...Object.values(deleteTransferPicSaga),
      ...Object.values(downloadFile),
      ...Object.values(audatexPics),
      ...Object.values(buyersSagas),
      ...Object.values(attachmentsSagas),
      ...Object.values(awardingsSagas),
      ...Object.values(searchSagas),
      ...Object.values(paymentsSagas),
      ...Object.values(filesSagas),
      ...Object.values(deliveriesSagas),
      ...Object.values(createOneCase),
      ...Object.values(catalogs2Sagas),
      ...Object.values(transfers),
      ...Object.values(moduleTransfer),
      ...Object.values(compensation),
      ...Object.values(inventory),
      ...Object.values(reports),
      ...Object.values(vehiclesIncoming),
      ...Object.values(vehicleIncomingDetails),
      ...Object.values(vehicleIncomingRegister),
      ...Object.values(vehicleEntryDetails),
      ...Object.values(inventoryPhotos),
      ...Object.values(updateVehicleEntries),
      ...Object.values(registerOutgoingVehicle),
      ...Object.values(transferCaseDetail),
      ...Object.values(updateCaseTransfer),
      ...Object.values(updateOutgoingVehicle),
      ...Object.values(notifications),
      ...Object.values(logs),
      ...Object.values(setCoverPic),
      ...Object.values(cancerReasonSagas),
      ...Object.values(componentSagas),
      ...Object.values(caseDamageSagas),
      ...Object.values(inventorySagas)
    ].map(fork)
  );
}
