import { takeLatest, put, call, select } from 'redux-saga/effects';
import { REQUEST_TIMEZONES, timeZonesSuccess, timeZonesFail } from 'redux/actions/catalogsActions';
import { listTimeZones } from 'services/catalogs';
import { getCatalogFromState } from './selectors';

function* requestTimeZonesAsync() {
  const timeZones = yield select(state => getCatalogFromState(state, 'timeZones'));

  if (typeof timeZones !== 'undefined' && timeZones.length > 0) {
    return timeZones;
  }

  try {
    const response = yield call(listTimeZones);
    if (typeof response !== 'undefined' && response.status === 200) {
      yield put(timeZonesSuccess(response.data.data));
    } else {
      yield put(timeZonesFail());
    }
  } catch (error) {
    yield put(timeZonesFail());
    console.log('error', error);
  }
}

/* This is watching action REQUEST_TIMEZONES, whenever is executed it starts
orchestrating the sideEffects   */
export function* watchRequestLanguages() {
  yield takeLatest(REQUEST_TIMEZONES, requestTimeZonesAsync);
}