import { takeLatest, put, call, select } from 'redux-saga/effects';
import {
  PICS_AUDATEX_FAV,
  PICS_AUDATEX_CHECK,
  PICS_AUDATEX_REQUEST,
  picsAudatexFail,
  picsAudatexSuccess,
  picsAudatexSet,
  picsAudatexSetBlockReuest,
  picsAudatexSetExist,
  PICS_AUDATEX_ADD,
  PICS_AUDATEX_REMOVE
} from 'redux/actions/picsActions';
import { getPhotosAudatex } from 'services/cases';
import { orderArrayObjects, responseOK } from 'utils';
import { getAudatexPhotos } from 'redux/sagas/selectors';

const helperFav = (photos, fileId) => {
  let withoutFind;
  const found = photos.data.find(item => item.fileId === fileId);
  if (found) {
    withoutFind = photos.data.filter(item => item.fileId !== fileId);
    if (found.favorite === false && found.checked === false) {
      withoutFind = [...withoutFind, { ...found, favorite: true, checked: true }];
    } else if (found.favorite === true && found.checked === true) {
      withoutFind = [...withoutFind, { ...found, favorite: false }];
    } else if (found.favorite === false && found.checked === true) {
      withoutFind = [...withoutFind, { ...found, favorite: true }];
    } else if (found.favorite === true && found.checked === false) {
      withoutFind = [...withoutFind, { ...found, favorite: false }];
    }
  }
  if (withoutFind && withoutFind.length > 0) return withoutFind.sort(orderArrayObjects('fileId'));
};

const helperCheck = (photos, fileId) => {
  let withoutFind;
  const found = photos.data.find(item => item.fileId === fileId);
  if (found) {
    withoutFind = photos.data.filter(item => item.fileId !== fileId);
    if (found.checked) {
      withoutFind = [...withoutFind, { ...found, checked: false }];
    } else {
      withoutFind = [...withoutFind, { ...found, checked: true }];
    }
  }
  if (withoutFind && withoutFind.length > 0) return withoutFind.sort(orderArrayObjects('fileId'));
};

function* blockNextRequest() {
  yield put(picsAudatexSetBlockReuest(true));
}

function* setFavoriteStatus(action) {
  blockNextRequest();
  const { payload } = action;
  const { fileId } = payload;
  const photos = yield select(state => getAudatexPhotos(state));
  const result = yield call(helperFav, photos, fileId);
  yield put(picsAudatexSet(result));
}

function* setCheckStatus(action) {
  blockNextRequest();
  const { payload } = action;
  const { fileId } = payload;
  const photos = yield select(state => getAudatexPhotos(state));
  const result = yield call(helperCheck, photos, fileId);
  yield put(picsAudatexSet(result));
}

function* requestAudatexPics(action) {
  const { payload } = action;
  const { caseId } = payload;
  let dataToSave = [];

  try {
    const response = yield call(getPhotosAudatex, caseId);
    if (responseOK(response)) {
      /** Formats each photo to before saving the response to the store
       * in this case adds favorite and checked atributtes to support
       * functionality of UniversePT pics
       */
      response.data.data.map(pic => {
        const formatedPicToSave = {
          ...pic,
          checked: false,
          favorite: false,
          isFromAudatex: true
        };
        dataToSave.push(formatedPicToSave);
      });

      if (response.data.data.length > 0) {
        yield put(picsAudatexSetExist(true));
      }
      yield put(picsAudatexSuccess(dataToSave.sort(orderArrayObjects('fileId'))));
    } else {
      yield put(picsAudatexFail());
    }
  } catch (_error) {
    yield put(picsAudatexFail());
  }
}

export function* watchAdd() {
  yield takeLatest(PICS_AUDATEX_ADD, blockNextRequest);
}

export function* watchrRemove() {
  yield takeLatest(PICS_AUDATEX_REMOVE, blockNextRequest);
}

export function* watchRequestLastPics() {
  yield takeLatest(PICS_AUDATEX_REQUEST, requestAudatexPics);
}

export function* watchFavoriteAudatexPic() {
  yield takeLatest(PICS_AUDATEX_FAV, setFavoriteStatus);
}

export function* watchCheckAudatexPic() {
  yield takeLatest(PICS_AUDATEX_CHECK, setCheckStatus);
}
