import { takeLatest, put, call } from 'redux-saga/effects';
import { MENU_REQUEST, setMenu, menuFail } from 'redux/actions/userActions';
import { getMenu } from 'services/users';
import { orderArrayObjects } from 'utils';

function* requestMenuAsync() {
  try {
    const response = yield call(getMenu);
    if (typeof response !== 'undefined' && response.status === 200) {
      let menuData = response.data.data;
      menuData.sort(orderArrayObjects('order'));
      const menuSorted = menuData;
      let aux = {};
      let pagesName = [];
      if (menuData.length > 0) {
        menuData.map(g => {
          let pages = [];
          let onlyPages = [];

          if (g.pages && g.pages.length > 0 && g.id !== 800) {
            g.pages.map(p => {
              if (p.url && g.visible) {
                pages = { ...pages, [`pageId${p.id}`]: p };
                let pageLower = p.url.toLowerCase();
                let isComplete = pageLower.endsWith('/');
                pageLower = isComplete ? pageLower : `${pageLower}/`;
                pagesName = [...pagesName, pageLower];
                onlyPages = [...onlyPages, p.id];
              } else if (p.pages && p.pages.length > 0 && g.visible) {
                pages = { ...pages, [`pageId${p.id}`]: p };
                p.pages.map(subpage => {
                  let pageLower = subpage.url.toLowerCase();
                  let isComplete = pageLower.endsWith('/');
                  pageLower = isComplete ? pageLower : `${pageLower}/`;
                  pagesName = [...pagesName, pageLower];
                  onlyPages = [...onlyPages, subpage.id];
                });
              }
              return onlyPages;
            });

            let auxInt = g;
            auxInt.pages = pages;
            auxInt.pagesId = onlyPages;
            aux = { ...aux, [`groupId${g.id}`]: auxInt };
          }

          return null;
        });
      }
      yield put(
        setMenu({
          menuObj: aux,
          menuArray: menuSorted,
          pagesAllowed: [...pagesName, 'account/myProfile']
        })
      );
    } else {
      yield put(menuFail());
    }
  } catch (_error) {
    yield put(menuFail());
  }
}

/* This is watching action REQUEST_COUNTRIES, whenever is executed it starts
orchestrating the sideEffects   */
export function* watchRequestMenu() {
  yield takeLatest(MENU_REQUEST, requestMenuAsync);
}
