import { takeLeading, put, call, takeEvery } from 'redux-saga/effects';
import {
  caseFilesRequest,
  CASE_FILES_REQUEST,
  caseFilesSuccess,
  caseFilesFail,
  CASE_FILES_ZIP_REQUEST,
  caseFileszipSuccess,
  caseFileszipFail,
  DOCUMENTS_CASE_REQUEST,
  documentsCaseSucess,
  documentsCaseFail,
  GET_GLOBAL_FILE_REQUEST,
  SAVE_FILE_RESOURCE,
  FileResourceSucess,
  GET_FILE_RESOURCELIST,
  DELETE_FILE_RESOURCE,
  GetFileResourceListSucces,
  GET_FILE_RESOURCE,
  GetFileResourceSucess
} from 'redux/actions/filesActions';
import { getFilesCase, getDocumentsCase } from 'services/cases';
import { downloadVehicleDirectory } from 'services/buyers';
import { getGlobalFile,uploadFileResource,getFileResourceList,
  deleteFileResource,getFileResource
} from 'services/files';
import { responseOK } from 'utils';
import { downloadReportFromNotification } from 'services/Queue';
import { sendNotification } from 'helpers/helperNotifications';
import { MSG_001,MSG_002 } from 'utils/messages';
import { getExtensionFile } from 'utils';

export function* workCaseFilesRequest(action) {
  const { payload } = action;
  try {
    const response = yield call(getFilesCase, payload);
    if (typeof response !== 'undefined' && response.status === 200) {
      yield put(caseFilesSuccess(response.data.data));
    } else {
      yield put(caseFilesFail());
    }
  } catch (error) {
    console.error('error', error);
    yield put(caseFilesFail());
  }
}

export function* workCaseFilesZipRequest(action) {
  const { payload } = action;
  const { caseId } = payload;
  try {
    const response = yield call(downloadVehicleDirectory, caseId);
    if (typeof response !== 'undefined' && response.status === 200) {
      yield put(caseFileszipSuccess(response.data.data));
    } else {
      yield put(caseFileszipFail());
    }
  } catch (error) {
    console.error('error', error);
    yield put(caseFileszipFail());
  }
}

export function* workDocumentsCaseRequest(action) {
  const { payload } = action;
  const { caseId, documentTypeId } = payload;
  try {
    const response = yield call(getDocumentsCase, caseId, documentTypeId);
    if (typeof response !== 'undefined' && response.status === 200) {
      yield put(documentsCaseSucess(response.data.data));
      //TODO: ONLY SEND WHEN FILES IS MORE THAN 0
      yield put(caseFilesRequest(payload));
    } else {
      yield put(documentsCaseFail());
    }
  } catch (error) {
    console.error('error', error);
    yield put(documentsCaseFail());
  }
}

export function* workerGlobalFileRequest(action){
  const { payload } = action;
  const { mainFolder, docType, foreignId } = payload;
  try {
    const response = yield call(getGlobalFile, {mainFolder, docType, foreignId});
    if(responseOK(response)){
      downloadReportFromNotification(response.data.data.content, response.data.data.contentType, response.data.data.tags);
    }
  } catch (error) {
    console.log(error);
  }
}

export function* SaveFileResource(action) {
  try {
    const { payload } = action;
    
    const response = yield call(uploadFileResource,payload);    
    if (responseOK(response)) {
      yield put(FileResourceSucess({add:true,data:response.data.data}));      
      // sendNotification('success', MSG_001);
      // const type = response.headers['content-type'];
      // const blob = new Blob([response.data], { type: type});
      // const link = document.createElement('a');
      // link.href = window.URL.createObjectURL(blob);
      // link.download = name;
      // link.click();

    }else
    {
      yield put(FileResourceSucess({add:true,data:response.data.data}));    
      // sendNotification('error', MSG_002); 
    }
  } catch (e) {
    sendNotification('error', e);
  }
}

export function* GetFileResourceList(action) {
  try {
    const { payload } = action;
    const response = yield call(getFileResourceList,payload);    
    if (responseOK(response)) {
      yield put(GetFileResourceListSucces(response.data.data));      
      // sendNotification('success', MSG_001);
      // const type = response.headers['content-type'];
      // const blob = new Blob([response.data], { type: type});
      // const link = document.createElement('a');
      // link.href = window.URL.createObjectURL(blob);
      // link.download = name;
      // link.click();

    }else
    {
      yield put(FileResourceSucess({}));    
      // sendNotification('error', MSG_002); 
    }
  } catch (e) {
    sendNotification('error', e);
  }
}


export function* DeleteFileResource(action) {
  try {
    const { payload } = action;
    const {id, fileResourceId} =payload;
    const response = yield call(deleteFileResource,fileResourceId);    
    if (responseOK(response)) {
      yield put(FileResourceSucess(response.data.data));      
      // sendNotification('success', MSG_001);
      // const type = response.headers['content-type'];
      // const blob = new Blob([response.data], { type: type});
      // const link = document.createElement('a');
      // link.href = window.URL.createObjectURL(blob);
      // link.download = name;
      // link.click();

    }else
    {
      yield put(FileResourceSucess({}));    
      sendNotification('error', MSG_002); 
    }
  } catch (e) {
    sendNotification('error', e);
  }
}

export function* GetFileResource(action) {
  try {
    const { payload } = action;
    const {id, fileResourceId,download,name} =payload;
    const response = yield call(getFileResource,fileResourceId);    
    if (responseOK(response)) {
      if(!download)
      {
        yield put(GetFileResourceSucess(response.data.data));      
      }else
      {

        let extension = getExtensionFile(name);
        console.log(extension);

        const linkSource = `data:application/pdf;base64,${response.data.data}`;
        const fileName =name.toLowerCase().includes(".pdf") ? name : name + ".pdf";

        const link = document.createElement('a');
        link.href = linkSource;
        link.download = fileName;
        link.click();
      }
      
      // sendNotification('success', MSG_001);


    }else
    {
      yield put(FileResourceSucess({}));    
      sendNotification('error', MSG_002); 
    }
  } catch (e) {
    sendNotification('error', e);
  }
}

export function* watchGetFileResource() {
  yield takeLeading(GET_FILE_RESOURCE, GetFileResource);
}

export function* watchDeleteFileResource() {
  yield takeLeading(DELETE_FILE_RESOURCE, DeleteFileResource);
}

export function* watchGetFileResourceList() {
  yield takeLeading(GET_FILE_RESOURCELIST, GetFileResourceList);
}

export function* watchSaveFileResource() {
  yield takeLeading(SAVE_FILE_RESOURCE, SaveFileResource);
}

export function* watchCaseFilesRequest() {
  yield takeLeading(CASE_FILES_REQUEST, workCaseFilesRequest);
}

export function* watchCaseFilesZipRequest() {
  yield takeLeading(CASE_FILES_ZIP_REQUEST, workCaseFilesZipRequest);
}

export function* watchdocumentsCaseRequest() {
  yield takeLeading(DOCUMENTS_CASE_REQUEST, workDocumentsCaseRequest);
}

export function* watchGetGlobalFileRequest() {
  yield takeEvery (GET_GLOBAL_FILE_REQUEST, workerGlobalFileRequest);
}
