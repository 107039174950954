import { takeLatest, put, call } from 'redux-saga/effects';
import { REQUEST_MARKETS, marketsSuccess, marketsFail } from 'redux/actions/catalogsActions';
import { listMarkets } from 'services/catalogs';

function* requestMarketsAsync(data) {
  const {payload} = data;
  try {
    const response = yield call(listMarkets);
    if (typeof response !== 'undefined' && response.status === 200) {
      let markets = response.data.data;
      if (payload && payload?.withAllOption) {
          markets = [{ id: '*****', businessName: 'Todos' }, ...markets];
      }
      yield put(marketsSuccess(markets));
    } else {
      yield put(marketsFail());
    }
  } catch (error) {
    yield put(marketsFail());
    console.error('error', error);
  }
}

export function* watchRequestMarkets() {
  yield takeLatest(REQUEST_MARKETS, requestMarketsAsync);
}
